@import 'bulma/bulma.sass';
@import 'bulma-switch';
/* @import 'bulma-checkradio'; */
/* @import 'bulma-divider'; */

/* $fa-font-path: '@fortawesome/fontawesome-free/webfonts' !default; */

@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import '@fortawesome/fontawesome-free/scss/regular.scss';
@import '@fortawesome/fontawesome-free/scss/brands.scss';

.has-margin-on-top {
    margin-top: 0.75rem;
}

.has-small-margin {
    margin: 0.37rem;
}

.has-small-margin-on-top {
    margin-top: 0.37rem;
}

.has-small-column-gap {
    column-gap: 0.25rem;
}

.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
}

svg {
    text {
        font-family: 'Ubuntu';
    }
}

body {
    padding-bottom: 75px;
    font-family: 'Ubuntu';
}

#action-panel {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    min-height: 75px;
}

ul.list {
    &.has-disc {
        list-style: disc;
        padding-left: 1rem;
    }
}

#svg-primary-button {
    fill: '#00d1b2';

    &:hover {
        /* fill: '#00c4a7'; */
        fill: 'red';
    }
}
